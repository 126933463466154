/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Header from "./header"

const Layout = ({ children, pageInfo }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Container fluid className="px-0 main" style={{background: `#5E6D7A`}}>
          <Row noGutters className="justify-content-center">
            <Col>
              <Header siteTitle={data.site.siteMetadata.title} pageInfo={pageInfo} />
            </Col>
          </Row>
          <Row noGutters style={{background: `#5E6D7A`}}>
            <Col>
              <Container>
                <main>{children}</main>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container fluid className="px-0" >
          <Row noGutters >
            <Col className="footer-col">
              <footer style={{
                    background: `#2d4152`,
                  }}>
<span><p>Bitte folge uns auf <a href="https://www.instagram.com/catcruise.gmbh/" target="_blank" rel="noopener noreferrer">Instagram</a> und/oder <a href="https://www.facebook.com/CatCruise-experience-freedom-479895192766064/" target="_blank" rel="noopener noreferrer">Facebook</a>.
<br/>© {new Date().getFullYear()} by CatCruise GmbH</p>
                </span>
              </footer>
            </Col>
          </Row>
        </Container>
      </>
    )}
  />
)

export default Layout
