import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import LogoImage from "./LogoImage"
import Navbar from "./navBar"
import { Row, Col } from "react-bootstrap"

const Header = ({ siteTitle, pageInfo }) => (
  <header
    style={{
      background: `#2d4152`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
    <Row>
      <Col>
        <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
              <LogoImage/>
          </Link>
        </div>
      </Col>
      <Col>
        <Navbar pageInfo={pageInfo} />
      </Col>
    </Row>

    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
//{siteTitle}

export default Header
